<template>
  <layout-navigation>
    <section :class="$style.page">
      <header :class="$style.header">
        <div :class="$style.control">
          <refresh
            :class="$style.mr"
            text="Обновить"
            @click="$store.dispatch('settings/refresh')"
          />
        </div>
      </header>

      <router-view />
    </section>
  </layout-navigation>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import LayoutNavigation from '@/layouts/navigation.vue';
import { getGeneralSettings, changeSettings } from '@/api/methods/settings';
import 'vue-toast-notification/dist/theme-default.css';
import Refresh from '@/components/common/filter/refresh.vue';

Vue.use(VueToast);

export default {
  components: {
    LayoutNavigation,
    Refresh,
  },
  data: () => ({
    settings: { frequency: null, storeTime: null },
  }),
  computed: {
    isGeneralSettingsRequired() {
      return this.$route.meta.requiredStoreParameters.generalSettings === true;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.settings = await getGeneralSettings();
    },

    async applySettings() {
      const res1 = await changeSettings(2, this.settings.frequency);
      const res2 = await changeSettings(4, this.settings.storeTime);

      if (res1 === 'success' && res2 === 'success') {
        Vue.$toast.open({
          message: 'Настройки успешно обновлены',
          type: 'success',
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
.page {
  background: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.header {
  box-shadow: $shadow;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px 30px;
}
.control {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  .mr {
    margin-right: 30px;
  }
}

.input {
  width: 50px;
  margin: 5px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 25px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.inputBlockContainer {
  width: 70%;
}
.button {
  margin: 20px;
  height: 30px;
  cursor: pointer;
  width: 95px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
.GeneralSettingsContainer {
  display: inline-flex;
}
</style>
